export const environment = {
    COMPANY_NAME: "evollo",
    production: true,
    APP_NAME: "QUALITY",
    apiUrl: "https://bradesco-quality.api.evollo.cloud",
    accountUrl: "https://bradesco-account.api.evollo.cloud",
    integrationUrl: "https://bradesco-integration.api.evollo.cloud",
    genAiUrl: 'https://genai-integrator-api.evollo.cloud:8485',
    features: {
          enabledAverageGrade: true,
          enabledChat: false,
          enableQualityDashboard: false,
          enableInteractionSentiment: false,
          enableSupport: false,
          enableFaq: false,
          homepage: '/interactions',
        cycleAsContactDate: true, // false = data de monitoria || true = data de contato
        enableInteractionMonitorCycle: false,
		aiEnabled: true,
	    aiInsightsEnabled: true,
	    aiInteractionEnabled: true,
	    trainingUrl: 'https://evollocombr.sharepoint.com/sites/bradescoinfo'
      },

    apps: {
      id: "https://bradesco.id.evollo.com.br",
      admin: "https://bradesco.admin.evollo.com.br",
    },
  };

